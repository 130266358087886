.about_section{
    padding: 30px 0px;
}

.about_sec_child {
    /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border-radius: 8px;
}

.community_sec {
    background-image: url('../../../assets/Images/About/community_5.png');
    background-size: cover;
    background-position: center center;
    min-height: 200px;
    border-radius: 8px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.cummunity_sec_child {
    background: rgba(0, 0, 0, 0.537);
    color: white !important;
    border-radius: 8px;
}

.about_regular_txt {
    font-family: "Inria Sans", sans-serif;
    font-weight: 400;
    font-style: normal;
}


@media only screen and (max-width: 767px) {
    .about_sec_perent {
        flex-direction: column-reverse;
    }

    .side_img {
        width: 200px;
    }
}

p ,ul li{
    font-size: 1.2rem !important;
}

