
/*---------------------------------------
  HERO        
-----------------------------------------*/
/* .hero_section {
    background-image: url('../../../assets/Images/background-hero.webp');
    background-color: var(--dark-color);
    position: relative;
    overflow: hidden;
    min-height: 620px;
    text-align: center;
    background-repeat: no-repeat;
   background-position: center;
   background-size: cover;
   padding: 30px 0px;

  }
  
  .hero_section::after {
    background-color: rgba(0, 0, 0, 0.65);
    border-radius: var(--border-radius-medium);
    content: "";
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-55%, -50%) rotate(45deg);
    width: 250px;
    height: 350px;
    pointer-events: none;
  }
  
  @media screen and (min-width: 991px) {
    .hero_section {
      height: 100vh;
    }
  }
  
  .hero_section h1 {
    color: var(--white-color);
  }
  
  .small-text {
    color: var(--secondary-color);
  }
  
  .hero_section .custom-border-btn {
    border-color: transparent;
  }
  
  .hero_section .container {
    position: relative;
    z-index: 9;
  }
  
  .hero-slides {
    width: 100%;
    height: 100%;
    position: absolute !important;
  } */

  .hero_section {
    /* background-image: url('../../../assets/Images/background-hero.webp'); */
    /* background-color: var(--dark-color); */
    position: relative;
    overflow: hidden;
    min-height: 500px;
    /* text-align: center; */
    background-repeat: no-repeat;
   background-position: center;
   background-size: cover;
   /* padding: 30px 0px; */
  
  }

  .hero_section {
    /* background-image: url('../../../assets/Images/background-hero.webp'); */
    /* background-color: var(--dark-color); */
    position: relative;
    overflow: hidden;
    min-height: 500px;
    /* text-align: center; */
    background-repeat: no-repeat;
   background-position: center;
   background-size: cover;
   /* padding: 30px 0px; */

  }

  .subtitle strong{
    text-transform: uppercase;
  }
  
  .banner_content{
    padding: 0px 100px;
    /* display: flex;
    flex-direction: column;
    align-items: flex-start; */
  }

  .banner_btn{
    background: red;
    color: white;
    border: none;
    padding: 10px;
    outline: none
  }

  .banner_desc{
    width: 40%;
  }

  @media only screen and (min-width: 200px) and (max-width: 767px)  {
    .banner_content{
      padding: 0px 25px;   
    }
    }

    @media only screen and (min-width: 300px) and (max-width: 375px)  {
      .hero_section{
        min-height: 200px;
      }
      .banner_content h2{
        font-size: 15px;
      }

      .subtitle strong{
        font-size: 10px !important;
      }

      .subtitle_inner h2{
        font-size: 10px !important;
      }

      .banner_content a{
        font-size: 10px !important;
      }

      .banner_desc{
        width:60%;
        font-size: 8px !important;
        color: black !important;
        padding: 0px !important;
      }
    
      }

      