 a {
    text-decoration: none;
    padding: 0;
}

.menu_link{
    opacity: 0.5;
    color: black;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 15px;
}

.head_search{
    opacity: 0.5;
    color: black;
    font-weight: 500;
    font-size: 15px;
}

.searchBtn{
    background: none;
    outline: none;
    padding: 15px;
    font-size: 14px;
    border-radius: 25px;
    border:1px solid black;
}

.contact_us_link{
    /* border: 1px solid; */
    background-color: #b92125 !important;
    color: white !important;
    border-radius: 5px !important;
    /* padding: 0px 18px; */
    
}

.contact_us_link:hover{
    /* border: 1px solid; */
    background-color: transparent !important;
    color: #b92125 !important;
    border-radius: 5px !important;
    border: 1px solid #b92125 !important;
    /* padding: 0px 18px; */
    
}


@media only screen and (max-width: 992px) {
    .contact_us_link{
        color: black !important;
        background-color: transparent !important;
    }

}
