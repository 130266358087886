@import url('https://fonts.googleapis.com/css2?family=Inria+Sans:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100..700;1,100..700&display=swap');


.social_urls_pages a{
  text-decoration: none;
  color: orange;
}

.footer_sec {
  background: #1f1f24;
  width: 100%;
  
}

.social_link_sec span {
  cursor: pointer;
}


.social_urls_pages:hover {
  color: #bb2026 !important;
  transition: all 0.5s ease;
  cursor: pointer;
  font-size: 18px !important;
}

.contact_txt {
  font-family: "Inria Sans", sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 48px;
}

.contact_regular_txt {
  font-family: "Inria Sans", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.footer_inpt::placeholder {
  color: white;
}

.footer_inpt {
  background: none;
  outline: none;
  border: none;
  background: white;
  border: 1px solid black;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  min-height: 34px;
  padding: 2px 6px;
  background: #fec7639c;
  color: white;
}

.btn_footer_email {
  min-height: 33px;
  border: none;
  background: none;
  background: #ffa600cc;
  outline: none;
  color: white;
  font-weight: 600;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}


@media only screen and (max-width: 767px) {
  .mobile_view_footer {
      padding: 0px 60px;
  }
}

@media only screen and (max-width: 425px) {
  .mobile_view_footer {
      padding: 0px 85px;
  }

  .footer_inpt {
      background: none;
      border: noe;
      outline: none;
      border: none;
      background: white;
      border: 1px solid black;
      border-radius: 5px;
      width: 100%;
      min-height: 34px;
      padding: 2px 6px;
      background: #fec7639c;
      color: white;
  }

  .btn_footer_email {
      min-height: 33px;
      border: none;
      background: none;
      background: #ffa600cc;
      outline: none;
      color: white;
      font-weight: 600;
      width: 100%;
      border-radius: 5px;
      margin-top: 5px;
  }
}