.floating_button {
    position: fixed;
    top: 50%; /* Vertically center the button */
    left: -68px; /* Adjust distance from the left */
    transform: rotate(270deg);
    z-index: 1000; /* Make sure it's above other elements */
  }
  
  .floating_button button {
    padding: 5px 20px;
    background-color: #b92125;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .floating_button button:hover {
    background-color: #b92125;
  }
  