.product_section {
    padding: 30px 0px;
  }
  
  .search_box {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ccc;
    padding-bottom: 5px;
    width: 300px; /* Adjust the width as needed */
  }
  
  .search_box input[type="text"] {
    border: none;
    outline: none;
    width: 100%;
    font-size: 16px;
  }
  
  .search_box input[type="text"]::placeholder {
    color: #888;
  }
  /* .product_nav {
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
      rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  }
  .product_nav ul li {
    font-size: 15px;
  } */
  /* 
  .card {
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .card:hover {
    transform: scale(1.05);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  }
  
  .card img {
    transition: transform 0.3s ease;
  }
  
  .card:hover img {
    transform: rotate(10deg) scale(1.1);
  } */
  
  /* .product_card p {
    font-size: 15px !important;
  }
  
  .product_card .product_card_inner {
      min-height: 360px;
      box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
      cursor: pointer;
      transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .product_card .product_card_inner:hover {
    transform: scale(1.05);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  }
  
  
  .product_strike_price {
    text-decoration: line-through;
    display: inline-block;
    color: #757575;
  }
  
  .product_nav_link a{
    text-decoration: none;
    color: black;
    font-size: large;
    font-weight: 400;
  } */
  
  
  .hero_section {
    /* background-image: url('../../../assets/Images/background-hero.webp'); */
    /* background-color: var(--dark-color); */
    position: relative;
    overflow: hidden;
    /* min-height: 500px; */
    /* text-align: center; */
    background-repeat: no-repeat;
   background-position: center;
   background-size: cover;
   /* padding: 30px 0px; */
  
  }
  
  .banner_content{
    padding: 0px 100px;
    /* display: flex;
    flex-direction: column;
    align-items: flex-start; */
  }
  
  .banner_content h2{
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    text-transform: uppercase;
  }
  
  .banner_btn{
    background: red;
    color: white;
    border: none;
    padding: 10px;
    outline: none
  }
  
  
  .productInner{
    display: none;
  }
  
  .productInner .grid_first{
    width: -webkit-fill-available;
   border-radius: 30px;
  }
  
  .productInner .grid_second{
    position: relative;
    overflow: hidden;
    /* min-height: 500px; */
    /* text-align: center; */
    background-repeat: no-repeat;
   background-position: center;
   background-size: cover;
   border-radius: 30px;
  }
  
  .product_heading{
    font-family: "Inria Sans", sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 48px;
  }
  
  .product_decs{
    /* font-family: sans-serif;
    font-size: 16px !important; */
    font-family: "Inria Sans", sans-serif;
    font-weight: 400;
    font-style: normal;
  }
  
  .product_detail_slides{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  
  
  @media only screen and (min-width: 200px) and (max-width: 767px)  {
    .banner_content{
      padding: 0px 25px;   
    }
    }
  
    @media only screen and (min-width: 200px) and (max-width: 768px)  {
      .social_media_second{
        /* padding: 0px 25px;    */
        display: flex;
        justify-content: center;
      }
      }
  
      @media only screen and (min-width: 300px) and (max-width: 375px)  {
        .hero_section{
          min-height: 200px;
        }
        .banner_content h2{
          font-size: 15px;
        }
        .productInner .grid_first{
          height: 160px;
         border-radius: 30px;
        }
  
        .product_detail_slides img{
          width: 300px;
          height: 300px;
        }
        }
  
      @media only screen and (min-width: 375px) and (max-width: 425px)  {
        .banner_content h2{
          font-size: 17px;
        }
        .hero_section{
          min-height: 250px;
        }
        .productInner .grid_first{
          height: 160px;
         border-radius: 30px;
        }
        .product_detail_slides img{
          width: 300px;
          height: 300px;
        }
        }
  
      @media only screen and (min-width: 425px) and (max-width: 768px)  {
        .hero_section{
          /* padding: 0px 25px;    */
          /* display: flex;
          justify-content: center; */
          min-height: 300px;
        }
        .productInner .grid_first{
          height: 160px;
         border-radius: 30px;
        }
  
        .product_detail_slides img{
          width: 300px;
          height: 300px;
        }
        }
  
        @media only screen and (min-width: 300px) and (max-width: 1200px)  {
          .angry_grid{
            /* padding: 0px 25px;    */
            display: none;
          }
          
          .productInner{
            display: block;
          }
          }
  
          
      @media only screen and (min-width: 768px) and (max-width: 1024px)  {
        .hero_section{
          /* padding: 0px 25px;    */
          display: flex;
          justify-content: center;
          min-height: 500px;
        }
  
        .productInner .grid_first{
          height: 200px;
         border-radius: 30px;
        }
  
        .product_detail_slides img{
          width: 300px;
          height: 300px;
        }
        }
  
      @media only screen and (min-width: 768px) and (max-width: 2400px)  {
        .hero_section{
          /* padding: 0px 25px;    */
          display: flex;
          justify-content: center;
          min-height: 500px;
        }
  
        .productInner .grid_first{
          height: 200px;
         border-radius: 30px;
        }
  
        }
    
  
    .social_media_img{
      width: 300px;
      height: auto;
    }
    .followus{
      padding: 50px;
      /* font-size: 12px; */
    }
  
  
    .followus p{
      font-size: 12px;
    }
  
    .followus h2{
      font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
      font-size: 22px;
    }
  
    .social_media{
      display: flex;
      flex-direction: column;
    }
  
    .social_media span{
      display: flex;
      font-size: 14px;
      line-height: 2.5rem;
      align-items: center;
  
      /* flex-direction: column; */
    }
  
  .all_products {
    text-align: center;
    padding: 20px;
    font-family: Arial, sans-serif;
    color: #333;
  }
  
  .all_products h1 {
    font-size: 2em;
    margin-bottom: 10px;
  }
  
  .all_products p {
    font-size: 1em;
    line-height: 1.6;
    max-width: 800px;
    margin: 0 auto;
  }
  
  
  .card_container {
    display: flex;
    gap: 20px;
  }
  
  .card {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
    /* width: 250px; */
    height: 245px; /* Fixed height for consistency */
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;
    overflow: visible; /* Allow image to overflow outside card */
    cursor: pointer;
    position: relative; /* To position child elements absolutely */
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end; /* Align content to bottom */
    align-items: center;
  }
  
  .product_image {
    width: 160px; /* Increased width for better visibility */
    height: 160px; /* Increased height to keep it balanced */
    object-fit: contain; /* Ensures the whole image is visible */
    transition: transform 0.3s ease;
    position: absolute; /* Absolute positioning to extend outside card */
    top: -50px; /* Adjust this value to control how much image is outside */
    left: 50%; /* Center image horizontally */
    transform: translateX(-50%); /* Center image */
    z-index: 1; /* Ensure image is above content */
  }
  
  .card_content {
    padding: 5px;
    text-align: center;
    background-color: #fff;
    font-size: 12px;
    width: 100%;
    transition: background-color 0.3s ease;
    z-index: 2; /* Ensure content is above the card */
    position: relative; /* Ensure text remains inside card */
  }
  
  .category {
    /* display: flex; */
    color: #666;
    font-size: 18px;
    /* text-transform: uppercase; */
    /* margin-bottom: 5px; */
  }
  
  .product_title {
    font-size: 18px;
    margin: 10px 0;
    color: #333;
  }
  
  .see_product {
    color: #e85a4f;
    text-decoration: none;
    font-weight: bold;
    font-size: 14px;
    transition: opacity 0.3s ease, transform 0.3s ease; /* Smooth transition effect */
    opacity: 0; /* Hidden by default */
    transform: translateY(10px); /* Start slightly lower for slide-up effect */
    margin-top: 10px;
  }
  
  /* Hover effects */
  .card:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    background-color: #fffbf0;
  }
  
  .card:hover .product_image {
    transform: scale(0.95) translateX(-50%); /* Slightly shrink image, keep centered */
  }
  
  .card:hover .see_product {
    opacity: 1; /* Fade in */
    transform: translateY(0); /* Slide up into view */
  }
  
  .card:hover .card_content {
    background-color: #fffbf0; /* Match card hover background */
  }
  
  .filter_heading{
    font-size: 32px;
  }

   
  
  .custom_card {
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  .custom_card img {
    object-fit: cover;
    height: 200px;
    width: 100%;
  }
  .custom_card_body {
    background-color: #f3e1bc;
    text-align: center;
    padding: 15px;
  }
  .custom_card_body h6 {
    font-weight: bold;
    color: #333;
  }
  .custom_card_body p {
    margin: 0;
    font-size: 10px;
    font-weight: 600;
    color: #555;
  }