/*

Tooplate 2137 Barista

https://www.tooplate.com/view/2137-barista-cafe

*/

/*---------------------------------------
  CUSTOM PROPERTIES ( VARIABLES )             
-----------------------------------------*/
:root {
  --white-color:                  #ffffff;
  --primary-color:                #BC6C25;
  --secondary-color:              #DDA15E;
  --section-bg-color:             #b78752;
  --custom-btn-bg-color:          #BC6C25;
  --custom-btn-bg-hover-color:    #DDA15E;
  --dark-color:                   #000000;
  --p-color:                      #717275;
  --border-color:                 #7fffd4;
  --link-hover-color:             #E76F51;

  --body-font-family:             'Plus Jakarta Sans', sans-serif;

  --h1-font-size:                 68px;
  --h2-font-size:                 46px;
  --h3-font-size:                 32px;
  --h4-font-size:                 28px;
  --h5-font-size:                 24px;
  --h6-font-size:                 22px;
  --p-font-size:                  20px;
  --btn-font-size:                16px;
  --form-btn-font-size:           18px;
  --menu-font-size:               16px;

  --border-radius-large:          100px;
  --border-radius-medium:         20px;
  --border-radius-small:          10px;

  --font-weight-thin:             200;
  --font-weight-light:            300;
  --font-weight-normal:           400;
  --font-weight-bold:             700;
}

body {
  background-color: var(--white-color);
  font-family: var(--body-font-family); 
}


/*---------------------------------------
  TYPOGRAPHY               
-----------------------------------------*/

/* h2,
h3,
h4,
h5,
h6 {
  color: var(--dark-color);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: var(--font-weight-bold);
}

h1 {
  font-size: var(--h1-font-size);
}

h2 {
  font-size: var(--h2-font-size);
}

h3 {
  font-size: var(--h3-font-size);
}

h4 {
  font-size: var(--h4-font-size);
}

h5 {
  font-size: var(--h5-font-size);
}

h6 {
  font-size: var(--h6-font-size);
} */

p {
  color: var(--p-color);
  font-size: var(--p-font-size);
  font-weight: var(--font-weight-light);
}

ul li {
  color: var(--p-color);
  font-size: var(--p-font-size);
  font-weight: var(--font-weight-light);
}

a, 
button {
  touch-action: manipulation;
  transition: all 0.3s;
}

a {
  display: inline-block;
  text-decoration: none;
  color: #000000;
}


b,
strong {
  font-weight: var(--font-weight-bold);
}


/*---------------------------------------
  SECTION               
-----------------------------------------*/

/* .section-padding {
  padding-top: 100px;
  padding-bottom: 100px;
}

.section-bg {
  background-color: var(--section-bg-color);
}

.section-overlay {
  background-color: var(--dark-color);
  position: absolute;
  z-index: 9;
  top: 0;
  left: 0;
  pointer-events: none;
  width: 100%;
  height: 100%;
  opacity: 0.65;
}

.section-overlay + .container {
  position: relative;
  z-index: 22;
}

.back-top-icon {
  font-size: var(--h2-font-size);
}
 */

/*---------------------------------------
  TIMELINE               
-----------------------------------------*/
.timeline {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
}

.timeline::after {
  content: '';
  position: absolute;
  width: 5px;
  background-color: var(--white-color);
  top: 0;
  bottom: 0;
  left: 50%;
  margin-left: -3px;
}

.timeline-container {
  padding: 10px 40px;
  padding-top: 0;
  position: relative;
  background-color: inherit;
  width: 50%;
}

.timeline-container::after {
  content: '';
  position: absolute;
  width: 25px;
  height: 25px;
  right: -12px;
  background-color: var(--white-color);
  border: 5px solid #7c5c52;
  top: 15px;
  border-radius: 50%;
  z-index: 1;
}

.timeline-container-left {
  left: 0;
}

.timeline-container-right {
  left: 50%;
}

.timeline-container-left::before {
  content: " ";
  height: 0;
  position: absolute;
  top: 9px;
  width: 0;
  z-index: 1;
  right: 25px;
  border: medium solid white;
  border-width: 20px 0 20px 20px;
  border-color: transparent transparent transparent white;
}

.timeline-container-right::before {
  content: " ";
  height: 0;
  position: absolute;
  top: 9px;
  width: 0;
  z-index: 1;
  left: 25px;
  border: medium solid white;
  border-width: 20px 20px 20px 0;
  border-color: transparent white transparent transparent;
}

.timeline-container-right::after {
  left: -13px;
}

.timeline-content {
  padding: 5px;
  background-color: var(--white-color);
  position: relative;
  border-radius: var(--border-radius-medium);
}

@media screen and (max-width: 991px) {
  .timeline::after {
    left: 31px;
  }

  .timeline-container {
    width: 100%;
    padding-left: 70px;
    padding-right: 25px;
  }

  .timeline-container::before {
    left: 58px;
    border: medium solid white;
    border-width: 20px 20px 20px 0;
    border-color: transparent white transparent transparent;
  }

  .timeline-container-left::after, .timeline-container-right::after {
    left: 6px;
  }

  .timeline-container-right {
    left: 0%;
  }
}


/*---------------------------------------
  CUSTOM BUTTON               
-----------------------------------------*/
.custom-btn {
  background: var(--custom-btn-bg-color);
  border: 2px solid transparent;
  border-radius: var(--border-radius-large);
  color: var(--white-color);
  font-size: var(--btn-font-size);
  font-weight: var(--font-weight-bold);
  line-height: normal;
  transition: all 0.3s;
  padding: 12px 28px;
}

.custom-btn:hover {
  background: var(--custom-btn-bg-hover-color);
  color: var(--white-color);
}

.custom-border-btn {
  background: transparent;
  border: 2px solid var(--white-color);
  color: var(--white-color);
}

.custom-border-btn:hover {
  background: var(--white-color);
  color: var(--secondary-color);
}

.custom-btn-bg-white {
  border-color: var(--white-color);
  color: var(--white-color);
}

.custom-btn-italic {
  font-style: italic;
}


/*---------------------------------------
  NAVIGATION BAR & OFFCANVAS              
-----------------------------------------*/
.sticky-wrapper {
  position: absolute;
  z-index: 999999;
  top: 0;
  right: 0;
  left: 0;
}

.sticky-wrapper.is-sticky .container {
  background: rgba(0, 0, 0, 0.65);
  border-radius: var(--border-radius-medium);
  padding: 15px 30px;
}

.navbar {
  /* background: transparent; */
  background-color: white;
  /* z-index: 999999; */
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  font-size: 20px;
}

.navbar .container {
  color: #000000;
  /* background: rgba(0, 0, 0, 0.35); */
  /* border-radius: var(--border-radius-medium); */
  /* padding: 15px 30px; */
}
/* 
.navbar-brand,
.navbar-brand:hover {
  font-size: var(--h4-font-size);
  font-weight: var(--font-weight-bold);
  display: block;
  color: var(--white-color);
} */

/* .navbar .navbar-brand-image {
  filter: brightness(0) invert(1);
} */

.navbar-brand-image {
  width: 130px !important;
  height: 55px !important;
}

.navbar .custom-btn {
  padding: 10px 20px;
}

.navbar-expand-lg .navbar-nav .nav-link {
  border-radius: var(--border-radius-large);
  margin: 10px;
  padding: 10px;
  color: #000000;
}

.navbar-nav .nav-link {
  display: inline-block;
  color: var(--white-color);
  font-size: var(--menu-font-size);
  font-weight: var(--font-weight-bold);
  padding-top: 15px;
  padding-bottom: 15px;
}

/* .navbar-nav .nav-link.active, 
.navbar-nav .nav-link:hover {
  color: var(--secondary-color);
} */

/* .navbar .dropdown-menu {
  background: var(--white-color);
  box-shadow: 0 1rem 3rem rgba(0,0,0,.175);
  border: 0;
  display: inherit;
  opacity: 0;
  min-width: 9rem;
  margin-top: 20px;
  padding: 13px 0 10px 0;
  transition: all 0.3s;
  pointer-events: none;
} */
/* 
.navbar .dropdown-menu::before {
  content: "";
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-bottom: 15px solid var(--white-color);
  position: absolute;
  top: -10px;
  left: 10px;
} */

/* .navbar .dropdown-item {
  display: inline-block;
  color: var(--p-color);
  font-size: var(--menu-font-size);
  font-weight: var(--font-weight-medium);
  position: relative;
} */
/* 
.navbar .dropdown-item.active, 
.navbar .dropdown-item:active,
.navbar .dropdown-item:focus, 
.navbar .dropdown-item:hover {
  background: transparent;
  color: var(--link-hover-color);
} */

/* .navbar .dropdown-toggle::after {
  content: "\f282";
  display: inline-block;
  font-family: bootstrap-icons !important;
  font-size: var(--menu-font-size);
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  vertical-align: -.125em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  left: 2px;
  border: 0;
}

@media screen and (min-width: 992px) {
  .navbar .dropdown:hover .dropdown-menu {
    opacity: 1;
    margin-top: 0;
    pointer-events: auto;
  }
}

.navbar-toggler {
  border: 0;
  padding: 0;
  cursor: pointer;
  margin: 0;
  width: 30px;
  height: 35px;
  outline: none;
} */
/* 
.navbar-toggler:focus {
  outline: none;
  box-shadow: none;
}

.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
  background: transparent;
}

.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before,
.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
  transition: top 300ms 50ms ease, -webkit-transform 300ms 350ms ease;
  transition: top 300ms 50ms ease, transform 300ms 350ms ease;
  transition: top 300ms 50ms ease, transform 300ms 350ms ease, -webkit-transform 300ms 350ms ease;
  top: 0;
}

.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
  transform: rotate(45deg);
}

.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
  transform: rotate(-45deg);
} */

/* .navbar-toggler .navbar-toggler-icon {
  background: var(--white-color);
  transition: background 10ms 300ms ease;
  display: block;
  width: 30px;
  height: 2px;
  position: relative;
} */

/* .navbar-toggler .navbar-toggler-icon:before,
.navbar-toggler .navbar-toggler-icon:after {
  transition: top 300ms 350ms ease, -webkit-transform 300ms 50ms ease;
  transition: top 300ms 350ms ease, transform 300ms 50ms ease;
  transition: top 300ms 350ms ease, transform 300ms 50ms ease, -webkit-transform 300ms 50ms ease;
  position: absolute;
  right: 0;
  left: 0;
  background: var(--white-color);
  width: 30px;
  height: 2px;
  content: '';
}

.navbar-toggler .navbar-toggler-icon::before {
  top: -8px;
}

.navbar-toggler .navbar-toggler-icon::after {
  top: 8px;
} */




/*---------------------------------------
  ABOUT              
-----------------------------------------*/
.about-section {
  background-color: var(--secondary-color);
  position: relative;
}

.about-section .ratio {
  border-radius: var(--border-radius-medium);
}

.custom-video {
  border-radius: var(--border-radius-medium);
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.about-image-wrap {
  border-radius: var(--border-radius-medium);
  position: relative;
  overflow: hidden;
}

.about-video-info {
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.35), rgba(188, 108, 37, 1));
  border-radius: var(--border-radius-medium);
  bottom: 0;
  height: auto;
  padding: 40px;
}

.about-video-info h4 {
  color: var(--white-color);
}

.about-section h6 {
  color: var(--secondary-color);
}

.team-block-wrap {
  background-color: var(--secondary-color);
  border-radius: var(--border-radius-medium);
  position: relative;
  overflow: hidden;
  max-height: 450px;
  cursor: pointer;
}

.team-block-wrap:hover .team-block-image {
  transform: scale(1.2);
}

.team-block-image-wrap {
  width: 100%;
}

.team-block-image {
  display: block;
  object-fit: cover;
  width: 100%;
  height: 100%;
  transition: all 0.3s;
}

.team-block-info {
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.35), rgba(188, 108, 37, 1));
  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 30px;
}

.team-block-info .badge {
  margin: auto;
  top: 2px;
  bottom: 0;
}


/*---------------------------------------
  OUR MENU              
-----------------------------------------*/
.menu-section {
  /* background-image: url('../images/happy-waitress-giving-coffee-customers-while-serving-them-coffee-shop.jpg'); */
  background-repeat: no-repeat;
  background-size: cover;
}

.menu-block-wrap {
  background: rgba(0, 0, 0, 0.65);
  border-radius: var(--border-radius-medium);
  padding: 45px;
}

.menu-block .border-top {
  border-top-color: rgba(255, 255, 255, 0.35) !important;
}

.menu-block h6 {
  color: var(--white-color);
}

.menu-block small {
  color: rgba(255, 255, 255, 0.35);
}

.menu-block strong {
  color: var(--secondary-color);
}

.menu-block-image {
  border-radius: 100%;
  width: 350px;
  height: 350px;
  object-fit: cover;
  display: block;
  margin: auto;
  cursor: pointer;
}

.badge {
  background-color: var(--primary-color);
  font-size: 12px;
  position: relative;
  bottom: 4px;
  padding-bottom: 6px;
}

.badge::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: -5px;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid var(--primary-color);
}


/*---------------------------------------
  REVIEWS              
-----------------------------------------*/
.reviews-section {
  background-color: #7c5c52;
}

.reviews-block {
  background-color: var(--section-bg-color);
  border-radius: var(--border-radius-medium);
  position: relative;
  overflow: hidden;
}

.reviews-block .border-top {
  border-top-color: rgba(255, 255, 255, 0.35) !important;
}

.reviews-block-image-wrap {
  /* background-image: url('../images/mid-section-waitress-wiping-espresso-machine-with-napkin-cafa-c.jpg'); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 30px;
}

.reviews-block-image {
  border-radius: var(--border-radius-large);
  width: 65px;
  height: 65px;
  object-fit: cover;
  margin-right: 10px;
}

.reviews-block-info {
  padding: 20px 30px;
}

.reviews-block-info p {
  font-size: var(--btn-font-size);
  font-style: italic;
  color: rgba(255, 255, 255, 0.75);
}

.reviews-group i {
  color: var(--white-color);
}


/*---------------------------------------
  BOOKING SECTION              
-----------------------------------------*/
.booking-section {
  /* background-image: url('../images/young-female-barista-wear-face-mask-serving-take-away-hot-coffee-paper-cup-consumer-cafe.jpg'); */
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
}

.booking-form-wrap {
  background-color: rgba(0, 0, 0, 0.65);
  border-radius: var(--border-radius-medium);
  position: relative;
  overflow: hidden;
  margin-top: 100px;
}

.booking-form {
  padding: 55px 65px;
}

.booking-form-image-wrap {
  position: relative;
  height: 100%;
}

.booking-form-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.booking-form-text span {
  color: var(--white-color);
  font-size: var(--btn-font-size);
}

.reservation-page .custom-border-btn {
  border-color: var(--secondary-color);
  color: var(--secondary-color);
}

.reservation-page .custom-border-btn:hover {
  border-color: transparent;
}


/*---------------------------------------
  CONTACT               
-----------------------------------------*/
.contact-section {
  /* background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.45), rgba(188, 108, 37, 1)), url('../images/young-female-barista-wear-face-mask-serving-take-away-hot-coffee-paper-cup-consumer-cafe.jpg'); */
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.google-map {
  border-radius: var(--border-radius-medium);
  filter: grayscale(100);
}

.contact-block-wrap {
  background: var(--primary-color);
  border-radius: var(--border-radius-medium);
  padding: 0;
  min-height: 200px;
}

.contact-block {
  background: var(--secondary-color);
  border-radius: var(--border-radius-medium);
  transform: rotate(8deg);
  height: 100%;
  padding: 20px 40px;
}

.contact-block h6 {
  transform: rotate(-8deg);
}

.contact-block .custom-icon {
  background: var(--primary-color);
  border-radius: var(--border-radius-large);
  display: block;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  position: relative;
}

.contact-block strong {
  display: block;
  color: var(--white-color);
  text-transform: uppercase;
  margin-top: 20px;
  margin-bottom: 10px;
}


/*---------------------------------------
  CUSTOM FORM               
-----------------------------------------*/
.custom-form .form-control {
  color: var(--p-color);
  border-radius: var(--border-radius-small);
  margin-bottom: 30px;
  padding-top: 13px;
  padding-bottom: 13px;
  box-shadow: none;
  outline: none;
  transition: all 0.3s;
}

.custom-form .form-control:hover,
.custom-form .form-control:focus {
  background: var(--primary-color);
  border-color: transparent;
}

.custom-form .form-label {
  color: var(--white-color);
  font-style: italic;
  margin-bottom: 15px;
}

.custom-form button[type="submit"] {
  background: var(--custom-btn-bg-color);
  border: none;
  border-radius: var(--border-radius-large);
  color: var(--white-color);
  font-size: var(--form-btn-font-size);
  font-weight: var(--font-weight-bold);
  transition: all 0.3s;
  margin-bottom: 0;
}

.custom-form button[type="submit"]:hover,
.custom-form button[type="submit"]:focus {
  background: var(--white-color);
  border-color: transparent;
  color: var(--primary-color);
}

.contact-form .form-control,
.booking-form .form-control {
  background-color: transparent;
  border-color: rgba(255, 255, 255, 0.25);
}

.booking-form .form-control {
  color: var(--white-color);
}

.custom-form input::-webkit-input-placeholder,
.custom-form textarea::-webkit-input-placeholder {
   color: var(--white-color);
}

.booking-form input:-moz-placeholder,
.booking-form textarea:-moz-placeholder {
  color: var(--white-color);
}


/*---------------------------------------
  SITE FOOTER              
-----------------------------------------*/
.site-footer {
  background-color: var(--primary-color);
  padding-top: 100px;
  padding-bottom: 100px;
}

.site-footer strong {
  color: var(--white-color);
}

.site-footer p,
.site-footer-link {
  color: rgba(255, 255, 255, 0.75);
  font-size: var(--btn-font-size);
}

.site-footer-link {
  color: rgba(255, 255, 255, 0.75);
  font-size: var(--btn-font-size);
  transition: all 0.3s;
}

.site-footer-link:hover {
  color: rgba(255, 255, 255, 0.75);
  text-decoration: underline;
}

.copyright-text {
  color: rgba(255, 255, 255, 0.75);
  font-size: var(--menu-font-size);
}

.copyright-text a {
	color: rgba(255, 200, 160, 0.75);
}


/*---------------------------------------
  SOCIAL ICON               
-----------------------------------------*/
.social-icon {
  margin: 0;
  padding: 0;
}

.social-icon-item {
  list-style: none;
  display: inline-block;
  vertical-align: top;
}

.social-icon-link {
  background: var(--primary-color);
  border-radius: var(--border-radius-large);
  color: var(--white-color);
  font-size: var(--menu-font-size);
  display: block;
  margin: 0 5px;
  text-align: center;
  width: 35px;
  height: 35px;
  line-height: 35px;
  transition: background 0.2s, color 0.2s;
}

.social-icon-link:hover {
  background: var(--white-color);
  color: var(--primary-color);
}

.social-icon-link span {
  display: block;
}

.swiper-pagination-bullet {
  width: 18px !important;
  height: 1px !important;
  background-color: #bf3437 !important;
  border: 1px solid #bf3437 !important;
  opacity: 1;
}

.swiper-pagination-bullet-active {
  width: 20px;
  height: 6px;
  transition: width 0.5s;
  border-radius: 5px;
  background: #68b8ff;
  border: 1px solid transparent;
}

/* Loader Styles */
.loader {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
  margin: auto;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full height to center it vertically */
}



/*---------------------------------------
  RESPONSIVE STYLES               
-----------------------------------------*/
@media screen and (max-width: 1200px) {
  h1 {
    font-size: 62px;
  }
}

@media screen and (max-width: 991px) {
  h1 {
    font-size: 54px;
  }

  h2 {
    font-size: 36px;
  }

  h3 {
    font-size: 32px;
  }

  h4 {
    font-size: 28px;
  }

  h5 {
    font-size: 20px;
  }

  h6 {
    font-size: 18px;
  }

  .section-padding {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .hero-section {
    padding-top: 148px;
    padding-bottom: 100px;
  }

  .navbar-brand,
  .navbar-brand:hover {
    font-size: var(--h5-font-size);
  }

  .navbar-expand-lg .navbar-nav {
    padding-bottom: 20px;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding: 0;
    margin-bottom: 0;
  }

  .navbar .custom-btn {
    margin-bottom: 10px;
  }

  .booking-form {
    padding: 45px;
  }

  .contact-section .container {
    width: auto;
    margin-right: 10px;
    margin-left: 10px;
    padding: 35px;
  }

  .site-footer {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

@media screen and (max-width: 767px) {
  .custom-btn {
    font-size: 14px;
    padding: 12px 20px;
  }

  .menu-block-wrap,
  .reviews-block-image-wrap,
  .reviews-block-info {
    padding: 20px;
  }
}


@media screen and (max-width: 578px) {
  .navbar .container,
  .sticky-wrapper.is-sticky .container {
    margin-right: 10px;
    margin-left: 10px;
  }
}


@media screen and (max-width: 480px) {
  h1 {
    font-size: 38px;
  }

  h2 {
    font-size: 28px;
  }

  h3 {
    font-size: 26px;
  }

  h4 {
    font-size: 22px;
  }

  h5 {
    font-size: 20px;
  }

  .hero-section::after {
    width: 200px;
    height: 200px;
  }

  .booking-form {
    padding: 35px;
  }

  .opening-hours-list li {
    font-size: 12px;
  }

  .reviews-block-image-wrap {
    flex-direction: column;
  }

  .reviews-block-image {
    margin-bottom: 15px;
  }

  .timeline-container {
    padding-left: 55px;
  }

  .timeline-container::before {
    left: 44px;
    border: medium solid white;
    border-width: 15px 15px 15px 0;
    border-color: transparent white transparent transparent;
  }

  .timeline-container-left::before,
  .timeline-container-right::before {
    top: 13px;
  }
}


.thumb-wrapper {
	padding: 15px 15px;
	/* background: #fff; */
	border-radius: 6px;
	text-align: center;
	position: relative;
  /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
}
 .img-box {
	height: 120px;
	margin-bottom: 20px;
	width: 100%;
	position: relative;
}
.img-box img {	
	max-width: 100px;
	max-height: 100px;
	display: inline-block;
	position: absolute;
	bottom: 0;
	margin: 0 auto;
	left: 0;
	right: 0;
  /* width: 70px;
  height: 70px; */
  /* width: 160px; */
    object-fit: contain;
}

.thumb-wrapper .category {
  color: #666;
  font-size: 12px;
  text-transform: uppercase;
  margin-bottom: 5px;
}

.thumb-wrapper .product_title {
  font-size: 18px;
  margin: 10px 0;
  color: #333;
}


.thumb-wrapper .card_content {
  padding: 15px;
  text-align: center;
  background-color: #fff;
  width: 100%;
  transition: background-color 0.3s ease;
  z-index: 2; /* Ensure content is above the card */
  position: relative; /* Ensure text remains inside card */
}


.thumb-wrapper .see_product {
  color: #e85a4f;
  text-decoration: none;
  font-weight: bold;
  font-size: 14px;
  transition: opacity 0.3s ease, transform 0.3s ease; /* Smooth transition effect */
  transform: translateY(10px); /* Start slightly lower for slide-up effect */
  margin-top: 10px;
}

.swiper-button-prev{
  color: #ff0000 !important;
}

.swiper-button-next{
  color: #ff0000 !important;
}


/* .product_inner .swiper-horizontal{
overflow: visible;
} */

@media (max-width: 768px) {
  .swiper-button-prev,
  .swiper-button-next {
    width: 40px  !important; 
    height: 40px  !important; 
  }

  .swiper-button-prev::after,
.swiper-button-next::after {
  font-size: 30px  !important; 
}
}



