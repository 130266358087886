.categories_sec_inner {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
}

.home_about_sec_inner {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
}

.categories_sec_inner span {
  font-size: 14px;
  font-weight: 700;
  color: #ff0000;
}

.home_about_sec_inner span {
  font-size: 14px;
  font-weight: 700;
  color: #ff0000;
}

.categories_sec_inner h1 {
  font-weight: 600;
  font-size: 38px;
  line-height: 1.6;
  letter-spacing: 0px;
  color: #11366d;
}

.home_about_sec_inner h1 {
  font-weight: 600;
  font-size: 38px;
  line-height: 1.6;
  letter-spacing: 0px;
  color: #11366d;
}

.image_sec {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  transition: transform 0.3s ease;
}

.elementor_icon_box_title {
  font-weight: 700;
  font-size: 20px !important;
  line-height: 0.6;
  letter-spacing: 0px;
  color: #11366d;
}

.elementor_icon_box_desc {
  font-weight: 400;
  font-size: 15px !important;
  line-height: 1.6;
  letter-spacing: 0px;
  color: #11366d;
  font-family: sans-serif;
}

.image_sec:hover img {
  cursor: pointer;
  /* transform: scale(0.95) translateX(-50%); Slightly shrink image, keep centered */
}

.image_sec img {
  width: 80%;
  border-radius: 50%;
  margin: 0 auto;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.cat_name {
  font-size: 20px !important;
  font-weight: 500 !important;
  color: #11366d !important;
}

.image_sec .shopnow a {
  font-size: 13px !important;
  font-weight: 500 !important;
  color: #ff0000 !important;
}

.home_about_sec_first {
  display: flex;
  flex-direction: column;
  align-items: end;
  /* justify-content: end; */
  text-align: end;
}

.home_about_inner_content {
  min-height: 150px;
}

.home_about_sec_second {
  display: flex;
  flex-direction: column;
  align-items: start;
  /* justify-content: start; */
  text-align: start;
}

.AboutHome {
  padding: inherit;
}

.AboutHome img {
  width: -webkit-fill-available;
}



.discount_inner_card{
    border-radius: 18px;
    background-color: #bfe2e6;
}


.discount_card_sec_inner span {
    font-size: 14px;
    font-weight: 700;
    color: #ff0000;
  }
  
  .discount_card_sec_inner h1 {
    font-weight: 600;
    font-size: 38px;
    line-height: 1.6;
    letter-spacing: 0px;
    color: #11366d;
  }

.discount_second_card{
    border-radius: 18px;
    background-color: #faebd7;
}

.discount_third_card{
    border-radius: 18px;
    background-color: #d1e0a9;
}

.discount_card_sec_inner .card_content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
  }

  .discount_card_sec_inner .card_content span {
    font-size: 14px;
    font-weight: 500;
    color: #ff0000;
    text-transform: uppercase;
  }
  
  
  .discount_card_sec_inner .card_content h1 {
    font-weight: 600;
    font-size: 28px;
    line-height: 1.0;
    letter-spacing: 0px;
    color: #11366d;
  }

  .discount_card_sec_inner .card_content button {
    font-size: 14px;
    font-weight: 700;
    fill: #000000;
    color: #000000;
    background-color: #FFFFFF;
    padding: 12px 30px 12px 30px;
    border: none;
    text-transform: uppercase;
    border-radius: 8px;
  }

  .discount_card_sec_inner .card_content button:hover {
    fill: #ffffff;
    color: #ffffff;
    background-color: #ff0000;;
  }

  .card_content_img img{
    position: relative;
    width: 220px;
    height: 200px;
  }

  .feed_back_sec_inner span {
    font-size: 14px;
    font-weight: 700;
    color: #ff0000;
  }
  
  .feed_back_sec_inner h1 {
    font-weight: 600;
    font-size: 38px;
    line-height: 1.6;
    letter-spacing: 0px;
    color: #11366d;
  }
  .feed_back_inner_card_details_dec{
    font-weight: 400;
    font-size: 15px !important;
    line-height: 1.6;
    letter-spacing: 0px;
    /* color: #11366d; */
    text-align: start;
    font-family: sans-serif;
  }

  .feed_back_inner_card{
    background-color: #f7f6f2;
    border-radius: 18px;
  }

  .feed_back_inner_card_user_img{
    width: 70px;
    height: 75px;
  }

  .feed_back_inner_card_details_job{
    font-weight: 400;
    font-size: 15px !important;
    line-height: 1.6;
    letter-spacing: 0px;
    /* color: #11366d; */
    font-family: sans-serif;
  }

  .feed_back_inner_card_details_name{
    color: #ff0000;
    font-size: 20px;
    font-weight: 700;
  }

  .blog_sec_inner span {
    font-size: 14px;
    font-weight: 700;
    color: #ff0000;
  }
  
  .blog_sec_inner h1 {
    font-weight: 600;
    font-size: 38px;
    line-height: 1.6;
    letter-spacing: 0px;
    color: #11366d;
  }

  .blog_sec_inner h5{
    font-size: 20px !important; 
    font-weight: 600 !important;
    line-height: 28px !important;
    color: #11366d !important;
  }

  .blog_sec_inner p{
    font-weight: 400;
    font-size: 15px !important;
    line-height: 1.6;
    letter-spacing: 0px;
    color: #11366d;
    font-family: sans-serif;
  }
  .blog_sec_inner img{
  width: -webkit-fill-available !important;
  object-fit: cover !important;
  border-radius: 15px !important;
  }



  .lts_Updts{
    background-color: #11366d;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: space-around;
    padding: 25px;
    flex-wrap: wrap;
  }
  
  .lts_Updts_first h2{
    font-weight: 600;
    font-size: 38px;
    line-height: 1.5;
    color: white;
  }

  .lts_Updts_second span{
    font-weight: 600;
    font-size: 18px;
    line-height: 1.5;
    color: white;
  }

  .lts_Updts_thirt input{
    padding: 0px 15px;
  }

@media only screen and (max-width: 650px) {
  .image_sec img {
    width: min-content;
  }
}

@media only screen and (max-width: 1024px) {
  .home_about_sec_first {
    align-items: center;
    text-align: center;
  }

  .AboutHome {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .AboutHome img {
    width: 70%;
  }

  .home_about_sec_second {
    align-items: center;
    text-align: center;
  }
}
