@import url('https://fonts.googleapis.com/css2?family=Inria+Sans:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100..700;1,100..700&display=swap');

.contact_txt {
    font-family: "Inria Sans", sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 48px;
}

.contact_regular_txt {
    font-family: "Inria Sans", sans-serif;
    font-weight: 400;
    font-style: normal;
}

.text_orange{
    --bs-text-opacity: 1;
    color: rgb(187 32 38) !important;
}

.contact_child_txt {
    font-family: "Roboto Mono", monospace;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
}

.social_sec {
    background: #f4f4f4;
    min-height: 85px;
    border-radius: 5px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.social_title {
    color: #6c757d;
    font-weight: 700;
    font-size: 1.4rem;
}

.details_sec {
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.08);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}


/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.form_control {
    /* border-radius: 5px !important; */
    min-height: 50px !important;
}

.form_control:focus {
    color: none !important;
    background-color: #fff;
    /* border: none; */
    /* border-color: rgb(187 32 38) !important; */
    outline: 0 !important;
    /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
}

.custom_btn {
    min-height: 55px;
    background: rgb(187 32 38);
    outline: none;
    border: none;
    /* border: 1px solid white; */
    border-radius: 5px;
    font-weight: 600;
    transition: all 0.5s ease;
    color : white;
    width:50%
}

/* .custom_btn:hover{
    border: 1px solid white;
    background: rgb(187 32 38);
    transition: all 0.5s ease;
    color: white;
} */