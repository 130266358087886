.ltsPro {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
}

.card {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    text-align: center;
}

.card img {
    width: 100%;
    height: auto;
}

.card h3 {
    margin: 10px 0;
    font-size: 18px;
    color: #333;
}

.card p {
    margin: 0;
    padding: 0 10px 10px;
    color: #777;
    font-size: 14px;
}