
.bst_slr{
    padding: 100px 0px;
}
.bst_text{
    font-family: var(--theme-font-heading), var(--font-oetker-moments-display);
    font-size: 1.75rem;
    font-weight: 700;
    line-height: 2.125rem;
}

.slider_bst{
    padding: 30px 0px;
}